<template>
    <div :class="$style.overview">
        <div :class="$style.desc">
            <div :class="$style.pic"></div>
            <div :class="$style.content">
                <div :class="$style.title">{{ $t('data_settings.install_basic_title') }}</div>
                <div :class="$style.content_desc">
                    <span v-html="$t('data_settings.install_ptx_desc2')"></span>
                    <pt-link :class="$style.gtm_link" type="primary" size="big" @back="handleConnectClick">
                        <pt-icon icon="icon-gtm" :iconStyle="{ width: '16px', height: '16px' }"></pt-icon>
                        {{ $t('data_settings.install_with_gtm') }}
                    </pt-link>
                </div>
            </div>
        </div>
        <div :class="$style.code">
            <div :class="$style.code_desc">
                <span v-html="$t('data_settings.install_basic_description1')"></span>
                <pt-link
                    type="primary"
                    size="small"
                    dom-type="a"
                    target="_blank"
                    v-if="userInfo.area === 'JP'"
                    :href="docUrl"
                    >{{ $t('data_settings.install_ptx_view_instruction_pttag') }}</pt-link
                >
            </div>

            <div :class="$style.code_content" v-if="profileInfo && profileInfo.profileSource">
                <div :class="$style.code_wrap" ref="codeWrap">
                    <code>
                        <pre>{{ currentCode }}</pre>
                    </code>
                </div>
                <pt-button
                    type="outline"
                    color="white"
                    size="small"
                    :class="$style.btn_copy"
                    @click="copyValue('profile')"
                >
                    {{ $t('common.button_copy_code') }}
                </pt-button>
            </div>

            <div
                :class="$style.cross_domain"
                v-role="{
                    type: 'crossDomain',
                    role: profileInfo.currentUserRole
                }"
                v-if="!isPtx && !crossDomain.loading"
            >
                <div :class="$style.cross_domain_title" @click="handleCrossDomainClick">
                    <i
                        class="el-icon-arrow-right"
                        :class="[crossDomain.visible && $style.is_active, $style.cross_domain_icon]"
                    ></i>
                    <span>{{ $t('data_settings.cross_domain_settings') }}</span>
                    <el-tooltip :content="$t('data_settings.cross_domain_tips')">
                        <pt-icon
                            icon="pt-icon--help-new"
                            :icon-style="{
                                width: '16px',
                                height: '16px',
                                fill: '#5E6C84'
                            }"
                        ></pt-icon>
                    </el-tooltip>
                </div>

                <div :class="$style.cross_domain_content" v-if="crossDomain.visible">
                    <p v-html="$t('data_settings.cross_domain_desc', { link: crossDomainDocUrl })"></p>

                    <div :class="$style.switch">
                        <span>{{ $t('data_settings.cross_domain_switch') }}</span>

                        <el-switch
                            class="el-switch__pt-small"
                            v-show="!crossDomain.updating"
                            :disabled="crossDomain.typeUpdating"
                            :width="28"
                            :value="crossDomain.value"
                            :active-value="1"
                            :inactive-value="0"
                            @change="val => queryDebounce('switch', val)"
                        ></el-switch>
                        <div class="code-loading" v-loading="true" v-if="crossDomain.updating"></div>
                    </div>

                    <div :class="$style.type" v-if="crossDomain.value">
                        <h4>{{ $t('data_settings.cross_domain_type_title') }}</h4>
                        <p>{{ $t('data_settings.cross_domain_type_desc') }}</p>

                        <div :class="$style.select">
                            <span>www.domain.com/path/</span>
                            <el-select
                                size="small"
                                :disabled="crossDomain.updating || crossDomain.typeUpdating"
                                :value="crossDomain.type"
                                @change="val => queryDebounce('type', val)"
                            >
                                <el-option
                                    v-for="item in crossDomainTypeOptions"
                                    :key="item.key"
                                    :label="item.label"
                                    :value="item.key"
                                ></el-option>
                            </el-select>
                            <!-- <div class="code-loading" v-loading="true" v-if="crossDomain.typeUpdating"></div> -->
                            <span>_pt_link=﹡﹡﹡﹡</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <gtm-client
            ref="gtmClient"
            v-if="visibleGtmClient"
            @close="handleClientPopupClose"
            @init="handleClientInit"
            @error="handClientError"
            @createFinish="handleCreateFinish"
        ></gtm-client>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GtmClient from './GtmClient';
import codeConfig from './code.config';
import { supplant } from '@/common/utils/string.utils';
import docsUrl from '@/common/configs/docsUrl';
import { debounce } from 'throttle-debounce';
import pteApis from '@/apis/pte.apis';
import commonUtils from '@/common/utils/common.utils';

export default {
    name: 'CodeOverview',

    data() {
        const crossDomainTypeOptions = [
            {
                key: 'HASH',
                label: '#'
            },
            {
                key: 'QUERY',
                label: this.$t('data_settings.cross_domain_type_query')
            }
        ];
        return {
            crossDomainTypeOptions,
            visibleGtmClient: false,
            crossDomain: {
                loading: true,
                value: 0,
                type: '',
                visible: false,
                updating: false,
                typeUpdating: false
            },
            gtmClient: {
                loading: false,
                init: false,
                error: null
            },
            docUrl: docsUrl.faqManualSetting,
            crossDomainDocUrl: docsUrl.crossDomain,
            queryDebounce: debounce(200, false, (type, val) => {
                this.updateCrossDomainStatus(type, val);
            })
        };
    },

    computed: {
        ...mapGetters('main', ['profileInfo', 'userInfo']),

        isPtx() {
            return this.profileInfo.profileSource === 'ptx';
        },

        currentCode() {
            const { domain, sid } = this.profileInfo || {};
            return supplant(codeConfig.pteInstallCode, { domain, sid });
        }
    },

    async mounted() {
        if (!this.isPtx) {
            const [err, res] = await commonUtils.awaitWrap(
                pteApis.pteSiteStatus(null, {
                    sid: this.profileInfo.sid
                })
            );
            this.crossDomain.value = res?.crossDomainEnabled ?? 0;
            this.crossDomain.type = res?.crossDomainType || 'HASH';
            this.crossDomain.loading = false;
        }
    },

    methods: {
        handleClientPopupClose() {
            this.visibleGtmClient = false;
        },

        handleConnectClick() {
            console.log('handleConnectClick');
            this.visibleGtmClient = true;
            this.$set(this.gtmClient, 'loading', true);
            const waitMaxTime = 30 * 1000;
            let waitTime = 0;

            const waitClientInit = setInterval(() => {
                waitTime += 50;
                const { init, error } = this.gtmClient;
                if (init || error || waitTime > waitMaxTime) {
                    clearInterval(waitClientInit);
                    init && this.$refs.gtmClient && this.$refs.gtmClient.handleSigninClick();

                    waitTime > waitMaxTime && this.handClientError('timeout');
                }
            }, 50);
        },

        handleClientInit() {
            this.gtmClient.init = true;
        },

        handClientError(errType) {
            this.gtmClient.error = errType;
            this.$set(this.gtmClient, 'loading', false);
            this.$message.error(this.$t(errType === 'createTag' ? 'setup.client_error' : 'setup.connect_fail'));
        },

        handleCreateFinish() {
            this.$router.push({ name: 'SetupSuccess' });
            this.$set(this.gtmClient, 'loading', false);
        },

        handleCrossDomainClick() {
            this.crossDomain.visible = !this.crossDomain.visible;
        },

        /**
         * @param {String} type: switch | type
         */
        async updateCrossDomainStatus(type, val) {
            let crossDomainType = this.crossDomain.type;
            let crossDomainEnabled = this.crossDomain.value;

            if (type === 'switch') {
                crossDomainEnabled = val;
                this.crossDomain.updating = true;
            } else {
                crossDomainType = val;
                this.crossDomain.typeUpdating = true;
            }

            const [err, res] = await commonUtils.awaitWrap(
                pteApis.updatePteStatus(null, {
                    data: {
                        crossDomainEnabled,
                        crossDomainType
                    },
                    where: {
                        sid: this.profileInfo.sid
                    }
                })
            );

            if (err) {
                this.$message.error(this.$t('common.update_failed'));
            } else {
                if (type === 'switch') {
                    this.crossDomain.value = val;
                    this.$message.success(
                        this.crossDomain.value
                            ? this.$t('data_settings.cross_domain_on')
                            : this.$t('data_settings.cross_domain_off')
                    );
                } else {
                    this.crossDomain.type = val;
                    this.$message.success(this.$t('data_settings.cross_domain_type_update_tips'));
                }
            }
            setTimeout(() => {
                this.crossDomain.updating = false;
                this.crossDomain.typeUpdating = false;
            }, 500);
        },

        copyValue(type) {
            this.$copyText(this.currentCode).then(this.copyValueSuccess, this.copyValueFail);
            let rangeDiv = this.$refs.codeWrap;
            let selection = window.getSelection();
            let range = document.createRange();
            rangeDiv && range.selectNode(rangeDiv);
            selection.removeAllRanges();
            selection.addRange(range);
        },

        copyValueSuccess() {
            this.$message.success(this.$t('common.tracking_code_copyright_tips'));
        },

        copyValueFail() {
            this.$message.error(this.$t('common.tracking_code_copyerror_tips'));
        }
    },

    components: {
        GtmClient
    }
};
</script>
<style lang="scss" module>
@import '@/styles/import.scss';
@import './code_install.scss';
.overview {
    @extend .wrapper;
    .pic {
        background-image: url('~@assets/images/setup/basic_code.svg');
    }
    .gtm_link {
        display: flex;
        align-items: center;
        height: 28px;
        line-height: 8px;
        margin-top: 8px;
    }
    .code_desc {
        :global(.highlight) {
            color: $pt-warning;
            font-weight: 600;
        }
    }
    .code_content {
        padding: 0 !important;
        .code_wrap {
            padding: 12px 20px;
            line-height: 24px;
        }
    }
    .cross_domain {
        margin-top: 40px;

        .cross_domain_title {
            font-size: 12px;
            color: $pt-black-600;
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
                margin: 0 6px 0 4px;
            }
        }

        .cross_domain_icon {
            font-size: 16px;
            transition: transform 0.3s;
            font-weight: 300;

            &.is_active {
                transform: rotate(90deg);
            }
        }

        .cross_domain_content {
            margin-top: 20px;
            color: $pt-black-600;
            font-size: 14px;

            p {
                margin-bottom: 16px;

                a {
                    color: $pt-green-60;
                    font-size: 12px;

                    &:hover {
                        color: $pt-black-600;
                    }
                }
            }

            .switch {
                display: flex;
                align-items: center;

                span {
                    margin-right: 6px;
                }
            }

            .type {
                margin-top: 16px;

                h4 {
                    font-size: 12px;
                    color: $pt-black-900;
                    font-weight: 500;
                }

                p {
                    font-size: 13px;
                    color: $pt-black-90;
                    margin: 4px 0 12px;
                }

                .select {
                    line-height: 32px;
                    font-size: 13px;
                    color: $pt-black-600;
                    display: flex;
                    align-items: center;

                    :global {
                        .el-select {
                            width: 120px;
                            margin: 0 4px;
                        }
                    }
                }
            }

            :global {
                .code-loading {
                    width: 20px;
                    height: 20px;
                    position: relative;

                    .el-loading-spinner {
                        margin-top: -10px;

                        .circular {
                            width: 20px;
                            height: 20px;
                        }

                        .path {
                            stroke: $pt-black-600 !important;
                        }
                    }
                }
            }
        }
    }
}
</style>
