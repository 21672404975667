var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.overview},[_c('div',{class:_vm.$style.desc},[_c('div',{class:_vm.$style.pic}),_c('div',{class:_vm.$style.content},[_c('div',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.$t('data_settings.install_basic_title')))]),_c('div',{class:_vm.$style.content_desc},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('data_settings.install_ptx_desc2'))}}),_c('pt-link',{class:_vm.$style.gtm_link,attrs:{"type":"primary","size":"big"},on:{"back":_vm.handleConnectClick}},[_c('pt-icon',{attrs:{"icon":"icon-gtm","iconStyle":{ width: '16px', height: '16px' }}}),_vm._v(" "+_vm._s(_vm.$t('data_settings.install_with_gtm'))+" ")],1)],1)])]),_c('div',{class:_vm.$style.code},[_c('div',{class:_vm.$style.code_desc},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('data_settings.install_basic_description1'))}}),(_vm.userInfo.area === 'JP')?_c('pt-link',{attrs:{"type":"primary","size":"small","dom-type":"a","target":"_blank","href":_vm.docUrl}},[_vm._v(_vm._s(_vm.$t('data_settings.install_ptx_view_instruction_pttag')))]):_vm._e()],1),(_vm.profileInfo && _vm.profileInfo.profileSource)?_c('div',{class:_vm.$style.code_content},[_c('div',{ref:"codeWrap",class:_vm.$style.code_wrap},[_c('code',[_c('pre',[_vm._v(_vm._s(_vm.currentCode))])])]),_c('pt-button',{class:_vm.$style.btn_copy,attrs:{"type":"outline","color":"white","size":"small"},on:{"click":function($event){return _vm.copyValue('profile')}}},[_vm._v(" "+_vm._s(_vm.$t('common.button_copy_code'))+" ")])],1):_vm._e(),(!_vm.isPtx && !_vm.crossDomain.loading)?_c('div',{directives:[{name:"role",rawName:"v-role",value:({
                type: 'crossDomain',
                role: _vm.profileInfo.currentUserRole
            }),expression:"{\n                type: 'crossDomain',\n                role: profileInfo.currentUserRole\n            }"}],class:_vm.$style.cross_domain},[_c('div',{class:_vm.$style.cross_domain_title,on:{"click":_vm.handleCrossDomainClick}},[_c('i',{staticClass:"el-icon-arrow-right",class:[_vm.crossDomain.visible && _vm.$style.is_active, _vm.$style.cross_domain_icon]}),_c('span',[_vm._v(_vm._s(_vm.$t('data_settings.cross_domain_settings')))]),_c('el-tooltip',{attrs:{"content":_vm.$t('data_settings.cross_domain_tips')}},[_c('pt-icon',{attrs:{"icon":"pt-icon--help-new","icon-style":{
                            width: '16px',
                            height: '16px',
                            fill: '#5E6C84'
                        }}})],1)],1),(_vm.crossDomain.visible)?_c('div',{class:_vm.$style.cross_domain_content},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('data_settings.cross_domain_desc', { link: _vm.crossDomainDocUrl }))}}),_c('div',{class:_vm.$style.switch},[_c('span',[_vm._v(_vm._s(_vm.$t('data_settings.cross_domain_switch')))]),_c('el-switch',{directives:[{name:"show",rawName:"v-show",value:(!_vm.crossDomain.updating),expression:"!crossDomain.updating"}],staticClass:"el-switch__pt-small",attrs:{"disabled":_vm.crossDomain.typeUpdating,"width":28,"value":_vm.crossDomain.value,"active-value":1,"inactive-value":0},on:{"change":function (val) { return _vm.queryDebounce('switch', val); }}}),(_vm.crossDomain.updating)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(true),expression:"true"}],staticClass:"code-loading"}):_vm._e()],1),(_vm.crossDomain.value)?_c('div',{class:_vm.$style.type},[_c('h4',[_vm._v(_vm._s(_vm.$t('data_settings.cross_domain_type_title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('data_settings.cross_domain_type_desc')))]),_c('div',{class:_vm.$style.select},[_c('span',[_vm._v("www.domain.com/path/")]),_c('el-select',{attrs:{"size":"small","disabled":_vm.crossDomain.updating || _vm.crossDomain.typeUpdating,"value":_vm.crossDomain.type},on:{"change":function (val) { return _vm.queryDebounce('type', val); }}},_vm._l((_vm.crossDomainTypeOptions),function(item){return _c('el-option',{key:item.key,attrs:{"label":item.label,"value":item.key}})}),1),_c('span',[_vm._v("_pt_link=﹡﹡﹡﹡")])],1)]):_vm._e()]):_vm._e()]):_vm._e()]),(_vm.visibleGtmClient)?_c('gtm-client',{ref:"gtmClient",on:{"close":_vm.handleClientPopupClose,"init":_vm.handleClientInit,"error":_vm.handClientError,"createFinish":_vm.handleCreateFinish}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }