import resourceWrapper from './resourceWrapper';

const resources = [
    {
        name: 'pteSiteStatus',
        query: `query pteSiteStatus($sid: String!){
            pteSiteStatus(sid:$sid){
                sid
                crossDomainType
                crossDomainEnabled
            }
        }`
    },
    {
        name: 'updatePteStatus',
        query: `mutation updatePteStatus($data: PteSiteStatusInput!, $where:PteSiteStatusWhereUniqueInput!) {
            updatePteSiteStatus(data:$data, where:$where)
        }`
    }
];

export default resourceWrapper(resources);
